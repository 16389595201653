.container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #0f162a;
    padding: 1rem;
  }
  
  .innerContainer {
    text-align: center;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 1rem;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
    max-width: 36rem;
    width: 100%;
    animation: fadeIn 0.6s ease-out;
  }
  
  .errorCode {
    font-size: 5rem; /* Reduced size from 6rem to 5rem */
    font-weight: 800;
    color: #0f162a;
    margin-bottom: 2rem; /* Increased margin to add more space */
    animation: slideDown 0.8s ease-out;
  }
  
  .errorMessage {
    font-size: 1.75rem; /* Reduced size from 1.875rem to 1.75rem */
    font-weight: 700;
    color: #1f2937;
    margin-bottom: 1.5rem; /* Add some margin for spacing */
    animation: fadeIn 1s ease-out;
  }
  
  .errorDescription {
    font-size: 1rem; /* Reduced size from 1.125rem to 1rem */
    color: #4b5563;
    margin-bottom: 2rem;
    animation: fadeIn 1.2s ease-out;
  }
  
  .homeButton {
    background-color: #0f162a;
    color: #ffffff;
    font-size: 1.125rem;
    font-weight: 600;
    padding: 0.75rem 1.75rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    transition: transform 0.3s, background-color 0.3s, box-shadow 0.3s;
    animation: bounceIn 1.2s ease-out;
  }
  
  .homeButton:hover {
    background-color: #000000;
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .homeIcon {
    margin-right: 0.5rem;
  }
  
  /* Animations */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes slideDown {
    0% {
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes bounceIn {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  